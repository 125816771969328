import React, { useState } from 'react';
import SignIn from './SignIn';
import SignUp from './SignUp';
import { Typography } from "antd";
import './Auth.css';

const Auth = () => {
    const [isSignIn, setIsSignIn] = useState(true);

    return (
        <div className="auth-container">
            <h1 className="auth-title">AUXILIUM</h1>
            {isSignIn ? <SignIn /> : <SignUp />}
            <Typography.Link
                onClick={() => setIsSignIn(!isSignIn)}
                className="auth-switch"
            >
                Switch to {isSignIn ? 'Sign Up' : 'Sign In'}
            </Typography.Link>
        </div>
    );
};

export default Auth;
