import React, { useState } from 'react';
import { Form, Input, Checkbox, Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import './Auth.css'; // 引入新的CSS样式

const SignUp = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const [agreed, setAgreed] = useState(false);

    const onFinish = values => {
        console.log('Received values of form: ', values);
        if (agreed) {
            // 处理注册逻辑
            navigate('/connectwallet');
        }
    };

    const onAgreeChange = e => {
        setAgreed(e.target.checked);
    };
    return (
        <Form form={form} name="register" onFinish={onFinish} scrollToFirstError className="auth-form">
            <Form.Item
                name="email"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!'
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!'
                    },
                ]}
            >
                <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    {
                        required: true,
                        message: 'Please input your password!',
                    },
                    () => ({
                        validator(_, value) {
                            if (!value || (value.length >= 8 && /[A-Z]/.test(value) && /[a-z]/.test(value) && /\d/.test(value) && /\W/.test(value))) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error('The password must be at least 8 characters, contain uppercase and lowercase letters, numbers, and special characters.'));
                        },
                    }),
                ]}
            >
                <Input type="password" placeholder="Password" />
            </Form.Item>

            <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                    }),
                ]}
            >
                <Input.Password placeholder="Confirm Password" />
            </Form.Item>

            <Form.Item>
                <Checkbox onChange={onAgreeChange}>I agree to the User Agreement</Checkbox>
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Register
                </Button>
            </Form.Item>
        </Form>
    );
};

export default SignUp;
