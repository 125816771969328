import React, { useState } from "react";
import axios from "axios";
import { Button, Modal, Layout, Form, Input, message, Popover } from "antd";
import TransparentHeader from "../../components/TransparentHeader/TransparentHeader";
import CustomSection from "../../components/CustomSection/CustomSection";
import MemberCarousel from "../../components/MemberCarousel/MemberCarousel";
import BaseFooter from "../../components/BaseFooter/BaseFooter";
import CustomCollapse from "../../components/CustomCollapse/CustomCollapse";
import CustomCard from "../../components/CustomCard/CustomCard";
import { useNavigate } from "react-router-dom";
import "./HomePage.css";

const HomePage = () => {
  const buttonStyle = { width: 250, marginBottom: 8, padding: "2px 15px" };
  const [showDialog, setShowDialog] = useState(false);
  const [form] = Form.useForm();
  const navigator = useNavigate();

  const weText = [
    {
      title: "Who are we ？",
      content: `We are over 20 specialized domain experts who have deep-rooted networks in different industries and are guided by a governing set of beliefs to improve the world via ethical means. We bring a pool of competences with a profound knowledge in our respective industries, bridging over one hundred years of all-hands-on-deck proficiency. 
        We are executives of global tech companies and VC funds, product managers, SaaS/SEM/SOI engineers, AI engineers & researchers, E-commerce & retail experts, data system experts, market research analysts, financial analysts, blockchain and crypto experts, smart contract & Web3 developers, creative directors, artists, designers, 3D CGI experts, fashion designers, fashion & luxury goods industry experts and consultants in various fields. Together, we are AUXILIUM (from Latin: help).`,
    },
    {
      title: "Our programs",
      content: (
        <>
          <p className="first-pro">
            AUXILIUM collaborates with any initiative meaning to bring under the
            spotlight women communities, especially women in business, in tech
            and in science. We bring to them the practical and down-to-earth
            dimension which has been missing. AUXILIUM promotes women
            empowerment around concrete, measurable and sometimes scalable
            projects. Technology driven tools and solutions needed to deploy
            such projects are provided free of charge to those women empowerment
            communities by AUXILIUM. As a result, women can deploy them at no
            cost, simply by contributing with their own professional skills and
            networks. In the context of a company, women can bring measurable
            results to the company without allocating financial resources, and
            as such usher AUXILIUM’s values while providing impactful tools,
            solutions, products and/or services. If you are a woman and part of
            a women community, it means we can empower you and your community
            with concrete benefits.
          </p>
          <p>
            AUXILIUM is currently deploying different programs including the
            Neighbourhood Alliance Initiative (NAIN, in Hebrew, refers to green
            pastures or loveliness; in French, it means dwarf). NAIN is a
            not-for-profit project federating, promoting and supporting local
            economy, micro-communities & associations through phygital Web3
            technology sponsorship, data driven omnichannel sales & marketing
            and event sponsoring. If you are a philanthropist, an art collector,
            an investor, an NGO, a sponsor, an event organizer, an art gallery,
            an antics & craft shop, a fashion retailer, a F&B business owner, a
            retail shop, a fitness & sport center, an association, a club, a
            social circle, an Artist, a musician, a doctor, a lawyer, a banker,
            etc. and you live, work or hang out in Hong Kong island, you will be
            able to benefit from NAIN which has been conceived to bring you
            branding, loyalty program, social recognition and returns.
          </p>
          <p>
            The KOC Alliance Monetization Initiative (KAMI program). We believe
            that true success is when social recognition and social status are
            paired with social responsibilities promoting aspirational values
            such as inclusiveness, equal access to education, food safety and
            sustainability principles. To dream is good, to take concrete
            actions is better. The KAMI program (KAMI – in Japanese, refers to
            gods) provides you – as KOC - marketing, branding, brand loyalty and
            monetizing tools as well as an entire team dedicated to helping you
            monetize your influence. Our purpose is to enable active ambassadors
            who influence their peers on consumption of products and services
            responding to our values.
          </p>
          <p>
            The Beacon Foundation. The Foundation is the legal, the human and
            especially the technological framework meant to practically and
            efficiently monetize the concept of fame as we do understand it. Its
            mission is to enable the Beacon (the child, the teenager or the
            young adult) to leverage their interest(s), talent(s), passion(s)
            and/or their dream(s) to become a personality/celebrity fuelling the
            profit engine for its own benefits while promoting SDG values for
            the hope of a better tomorrow.
          </p>
          <p>And so on.</p>
          <p>Contact us via info@axm.foundation</p>
        </>
      ),
    },
  ];

  const docText = [
    {
      title: "DOC 1: AUXILIUM Impact Fund Summary (English or Chinese)",
      content:
        "This very short document (less than 5 pages) summarizes what our focus is, why and how our Impact Fund will mechanically bring to LPs potentially higher returns compared with regular funds.",
    },
    {
      title: "DOC 2: Investment Memo (English or Chinese)",
      content:
        "This short document (less than 10 pages) explains in more details why our Impact Fund will mechanically bring to LPs potentially higher returns compared with regular funds. The Memo explains also the categorization of Chinese HNWIs according to studies, and how AUXILIUM is unique by responding to their needs.",
    },
    {
      title: "DOC 3: Processes (English or Chinese)",
      content:
        "This very short document (less than 5 pages) is focused on how LPs and SPVs are articulated from the investment point of view. The document introduces how documents are distributed to LPs.",
    },
    {
      title: "DOC 4: Fund Structure & SPVs (English)",
      content:
        "This short document (less than 10 pages) explains the structure of the fund and how a LP can multiply potential sources of returns. The LP comes to realize how the fund can provide higher potential returns than regular VCs, also how customization can effectively impact massively those returns.",
    },
    {
      title: "DOC 5: The Impact Thesis (English or Chinese)",
      content:
        "This very short document (less than 5 pages) summarizes what our focus is, why and how our Impact Fund will mechanically bring to LPs potentially higher returns compared with regular funds.",
    },
    {
      title: "DOC 6: Warehouse SPVs",
      content: (
        <>
          <p>
            Each short document (less than 10 pages) describes briefly one
            specific SPV.
          </p>
          <ul>
            <li>
              Equity Crowdfunding & Lending Platform is a SPV project allowing
              LPs (and first Leading LP) to generate potentially substantial and
              additional incomes with very high degree of flexibility.
            </li>
            <li>
              The Beacon Foundation SPV provides to LPs’ (and first Leading LP)
              child(ren) a potential access to top schools around the world
              while providing fame.
            </li>
            <li>
              The WISE Real Estate SPV: real estate is regarded by Chinese
              people as a fundamental investment, free of risk, especially in a
              long horizon. AUXILIUM has morphed the real estate business model
              and put it on steroid thanks to the injection of new technologies.
              Simply put, AUXILIUM has reached a higher return than the
              benchmark. This project exemplifies how AUXILIUM provides
              customization according to HNWIs’ risk profiles.
            </li>
            <li>And so on.</li>
          </ul>
        </>
      ),
    },
  ];

  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    setVisible(!visible);
  };

  const showModal = () => {
    //setShowDialog(true)
    window.location.href = "mailto:support@example.com";
  };

  const timelines = [
    {
      children:
        "End of April: launch of SANE (Seminar Accelerating Network Endeavor)",
    },
    {
      children: "End of May: launch the website for partners",
      color: "green",
    },
    {
      children:
        "June: launch of WISE, ICARIUS, KAMI and BE MY VALENTINE project with partners",
    },
    {
      children: "July: launch of NAIN and BE MY VALENTINE project ",
      color: "green",
    },
    {
      children:
        "August: launch of SHERLOCK SYNDROMA project with partners, launch of ICARIUS & KAMI",
    },
    {
      children: "September: launch of HYPER CAR project with partner",
      color: "green",
    },
    {
      children: "October: launch of WOO with partners",
    },
    {
      children: "November/December: launch our VC fund Phase I",
    },
  ];

  const handleOk = async () => {
    try {
      const formData = await form.validateFields();
      const email = formData.email;
      try {
        const resp = await axios.post("http://localhost:8080/api/email", {
          email: email,
        });
        console.log(resp);
        if (resp.errno === 0) {
          message.success(resp.data.errmsg);
        } else {
          message.warning(resp.data.errmsg);
        }
      } catch (error) {
        message.error(
          "An error occurred while submitting the form. Please try again later."
        );
      }
      // 这里执行发送POST请求的操作
      // 示例: axios.post('your_api_url', { email: formData.email });
      setShowDialog(false);
    } catch (error) {
      // 如果验证失败，antd会自动显示错误提示
    }
  };

  const handleCancel = () => {
    setShowDialog(false);
  };

  return (
    <Layout>
      <Modal
        title="Create a Account"
        open={showDialog}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Confirm"
        cancelText="Cancel"
      >
        <p>
          Our product will be launched soon. You can leave your email address
          and we will notify you as soon as the product is available.
        </p>
        <Form form={form}>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email address." },
            ]}
          >
            <Input placeholder="Enter your email address" />
          </Form.Item>
        </Form>
      </Modal>
      <TransparentHeader />
      <CustomSection
        className="section-1-bg"
        flex="block"
        backgroundColor="rgb(1, 29, 56)"
      >
        <div
          style={{
            maxWidth: 960,
            textAlign: "center",
            margin: "0 auto 90px auto",
          }}
          className="main-container"
        >
          <div
            className="home-slogan"
            style={{ fontSize: 30, margin: "400px 0 64px 0", color: "#f2f2f2" }}
          >
            Accelerating exchanges and revenue streams between corporations,
            MSMEs, communities and individuals
          </div>
          <Button
            type="primary"
            style={{
              ...buttonStyle,
              backgroundColor: "#011d38",
              borderColor: "lightKleinBlue",
              marginBottom: 8,
              fontSize: 16,
            }}
          >
            <a target="_blank" href="mailto:info@axm.foundation">
              {" "}
              Connect with us{" "}
            </a>
          </Button>
          <div>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: "#8a8a8a",
                borderColor: "#8a8a8a",
                color: "lightKleinBlue",
                fontSize: 16,
              }}
              onClick={() => navigator("./pdfreader")}
            >
              <a>Access to materials</a>
            </Button>
          </div>
          <div>
            <Button
              style={{
                ...buttonStyle,
                backgroundColor: "#8a8a8a",
                borderColor: "#8a8a8a",
                color: "lightKleinBlue",
                fontSize: 16,
              }}
            >
              <a href="#seminar">Find out our offline activities</a>
            </Button>
          </div>
          <div className="cards-container">
            <CustomCard
              title="About Us"
              description="Focusing on APAC with first deployments in Hong Kong, we foster AI driven technologies enhancing omnichannel immersive sales experiences and we enable ecosystems blurring the frontiers between the physical and the digital (phygital). AUXILIUM is focused on accelerating exchanges and revenue streams between corporations, MSMEs, communities and individuals. In short, AUXILIUM is specialized in data centric AI driven tools & solutions accelerating and amplifying existing source of income as well as creating new ones by responding to untapped demands. AUXILIUM is structuring itself around an emerging micro VC (less than < 100 MM USD). The Fund is governed by a set of beliefs pivoting around SDG values and the necessity to improve the world via ethical means. Information can be requested at info@axm.foundation"
              anchor="Neighbourhood"
              style={{ backgroundColor: "#8a8a8a", borderColor: "#8a8a8a" }}
            />
            <CustomCard
              title="Deal Flow"
              description="Studies show that historically and statistically an emerging early stage VC performs significantly better than the industry especially in downturn market environment. Furthermore, AUXILIUM has morphed and tested different business models in order to generate blitzscaling growth. As a result, we are manufacturing it by increasing mechanically larger ownership and lower dilution rate at a discount price. De facto, we improve significantly the potential number and scale of liquidity events with mathematically high probability to blow away the average yearly return of 20% made by top VCs."
              anchor="ICARIUS"
            />
            <CustomCard
              title="Customization Needs of LPs"
              description="AUXILIUM attaches an utmost importance to its relations with its LPs. We deploy considerable resources to concretely understand them. For us, understanding them means responding to their needs individually by providing instruments allowing investment customization and flexibility according to their specific risk profile, personal values and philanthropic goals. We go even further. HNWIs and Family Offices have their own networks and have often direct ownership of companies."
              anchor="KAMI"
            />
          </div>
        </div>
      </CustomSection>
      <CustomSection
        id="Neighbourhood"
        backgroundColor="#011d38"
        className="Neighbourhood"
      >
        <div
          className="content-container"
          style={{
            maxWidth: 960,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#f2f2f2",
          }}
        >
          <div
            className="text-container"
            style={{
              width: "80%",
              textAlign: "justify",
            }}
          >
            <h2 style={{ fontSize: 24, marginBottom: 8, textAlign: "left" }}>
              About Us
            </h2>
            <p
              className="article"
              style={{
                fontSize: 16,
                marginTop: 28,
                wordBreak: "break-word",
                letterSpacing: 2,
                textAlign: "justify",
              }}
            >
              Focusing on APAC with first deployments in Hong Kong, we foster AI
              driven technologies enhancing omnichannel immersive sales
              experiences and we enable ecosystems blurring the frontiers
              between the physical and the digital (phygital).AUXILIUM is
              focused on accelerating exchanges and revenue streams between
              corporations, MSMEs, communities and individuals. In short,
              AUXILIUM is specialized in data centric AI driven tools &
              solutions accelerating and amplifying existing source of income as
              well as creating new ones by responding to untapped demands.
              AUXILIUM is structuring itself around an emerging micro VC (less
              than 100 MM USD). The Fund is governed by a set of beliefs
              pivoting around SDG values and the necessity to improve the world
              via ethical means. Information can be requested at
              info@axm.foundation
            </p>
          </div>
        </div>
      </CustomSection>
      <CustomSection id="ICARIUS" backgroundColor="#8a8a8a" className="ICARIUS">
        <div
          className="content-container"
          style={{
            maxWidth: 960,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="text-container"
            style={{
              width: "80%",
              textAlign: "center",
            }}
          >
            <h2
              style={{
                fontSize: 24,
                marginBottom: 8,
                textAlign: "left",
                marginTop: 50,
              }}
            >
              {" "}
              Deal Flow{" "}
            </h2>
            <p
              className="article"
              style={{ fontSize: 16, textAlign: "justify", marginTop: 28 }}
            >
              Studies show that historically and statistically an emerging early
              stage VC performs significantly better than the industry
              especially in downturn market environment. Furthermore, AUXILIUM
              has morphed and tested different business models in order to
              generate blitzscaling growth. As a result, we are manufacturing it
              by increasing mechanically larger ownership and lower dilution
              rate at a discount price. De facto, we improve significantly the
              potential number and scale of liquidity events with mathematically
              high probability to blow away the average yearly return of 20%
              made by top VCs.
              <br />
              <br />
              Deal flow is the essence of any VC. VCs live and die by their
              founders. AUXILIUM deploys a unique deal flow strategy resonating
              cross industries with talents and experts alike. Aggregated and
              aligned around culture and values, the model generates network and
              compounding effects, capital strict discipline, milestones and a
              highly emulating ecosystem fostering innovation at all levels.
              Where others compete and overvalue start-ups, we produce them at
              extraordinarily low-cost (or no cost) directly with a large array
              of market insiders and potentially with LPs and clients directly.
              As a result, time to MVPs (Minimum Viable Product) and PMFs
              (Product Market Fit) is extraordinarily optimized which creates a
              massive blitzscaling effect.
            </p>
          </div>
        </div>
      </CustomSection>
      <CustomSection
        id="KAMI"
        backgroundColor="#011d38"
        color="#f2f2f2"
        className="KAMI"
      >
        <div
          className="content-container"
          style={{
            maxWidth: 960,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="text-container"
            style={{
              width: "80%",
              textAlign: "center",
            }}
          >
            <h2
              className="mobile"
              style={{ fontSize: 24, marginBottom: 8, textAlign: "left" }}
            >
              Customization Needs of LPs
            </h2>
            <p
              className="article"
              style={{ fontSize: 16, textAlign: "justify", marginTop: 28 }}
            >
              AUXILIUM attaches an utmost importance to its relations with its
              LPs. We deploy considerable resources to concretely understand
              them. For us, understanding them means responding to their needs
              individually by providing instruments allowing investment
              customization and flexibility according to their specific risk
              profile, personal values and philanthropic goals. We go even
              further. HNWIs and Family Offices have their own networks and have
              often direct ownership of companies.
              <br />
              <br />
              We can provide tailor made tools & solutions via our deal flow
              model at almost no cost. It means that we practically push the
              customization in ways that our LPs can directly and effectively
              leverage our hub of technologies.
              <br />
              <br />
              AUXILIUM is not seeking any funding from institutional
              organizations, companies or any other funds. AUXILIUM is mainly
              focused to be culturally close to its LPs. It means that we are
              focused mainly on Mainland Chinese or Chinese ascendant HNWIs and
              Family Offices.
            </p>
          </div>
        </div>
      </CustomSection>
      <CustomSection backgroundColor="#8a8a8a" className="collapse-main">
        <div
          className="content-container"
          style={{
            maxWidth: 960,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="text-container collleapse-ct"
            style={{
              textAlign: "justify",
              width: "768px",
            }}
          >
            <CustomCollapse info={weText} />
          </div>
        </div>
      </CustomSection>
      <CustomSection backgroundColor="#011d38">
        <MemberCarousel />
      </CustomSection>
      <CustomSection backgroundColor="#8a8a8a" id="seminar" className="seminar">
        <div
          className="content-container"
          style={{
            maxWidth: 960,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="text-container"
            style={{
              width: "80%",
              textAlign: "justify",
            }}
          >
            <h2 style={{ fontSize: 24, marginBottom: 8 }}> Seminars </h2>
            <p
              className="article"
              style={{ fontSize: 16, marginBottom: 60, marginTop: 28 }}
            >
              Our goal is simple. We want to explain why and how we can be
              agents of change and how social recognition and social status make
              sense when they are paired with social responsibilities. We are
              here to provide you technical and marketing sponsorship which
              empowers you with tools to generate a potential income and become
              a beacon among your peers. Seminars are organized in a salon
              format and dedicated to associations, communities, NGOs, clubs,
              art galleries and social circles. We bring educational materials,
              and very practical and down-to-earth examples of how you can
              profit from the technology. We have no hidden agenda as we are
              not-for-profit. Nevertheless, we express clearly our goals and
              demonstrate how a set of technologies can elevate you in a
              meaningful and significant way. From April 2023, we started to
              organize a series of seminars called “Stay SANE” with different
              offline partners ranging from art galleries to different
              associations. We will introduce a new series from July. More
              information will be shared closer to date.
            </p>
          </div>
        </div>
      </CustomSection>
      <CustomSection
        backgroundColor="#011d38"
        color="#f2f2f2"
        className="Materials"
      >
        <div
          className="content-container"
          style={{
            maxWidth: 960,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="text-container box_ball_first_123"
            style={{
              width: "768px",
              textAlign: "justify",
            }}
          >
            <h2 style={{ fontSize: 24, marginBottom: 28 }}> Materials </h2>
            {/*<ul>*/}
            {/*    <li>*/}
            {/*        <p>DOC 1: AUXILIUM Impact Fund Summary (English or Chinese)</p>*/}
            {/*        <p>This very short document (less than 5 pages) summarizes what our focus is, why and how our Impact Fund will mechanically bring to LPs potentially higher returns compared with regular funds.</p>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <p>DOC 2: Investment Memo (English or Chinese)</p>*/}
            {/*        <p>This short document (less than 10 pages) explains in more details why our Impact Fund will mechanically bring to LPs potentially higher returns compared with regular funds. The Memo explains also the categorization of Chinese HNWIs according to studies, and how AUXILIUM is unique by responding to their needs.</p>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <p>DOC 3: Processes (English or Chinese)</p>*/}
            {/*        <p>This very short document (less than 5 pages) is focused on how LPs and SPVs are articulated from the investment point of view. The document introduces how documents are distributed to LPs.</p>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <p>DOC 4: Fund Structure & SPVs (English)</p>*/}
            {/*        <p>This short document (less than 10 pages) explains the structure of the fund and how a LP can multiply potential sources of returns. The LP comes to realize how the fund can provide higher potential returns than regular VCs, also how customization can effectively impact massively those returns.</p>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <p>DOC 5: The Impact Thesis (English or Chinese)</p>*/}
            {/*        <p>This very short document (less than 5 pages) summarizes what our focus is, why and how our Impact Fund will mechanically bring to LPs potentially higher returns compared with regular funds.</p>*/}
            {/*    </li>*/}
            {/*    <li>*/}
            {/*        <p>DOC 6: Warehouse SPVs</p>*/}
            {/*        <p>Each short document (less than 10 pages) describes briefly one specific SPV.</p>*/}
            {/*        <ul>*/}
            {/*            <li>*/}
            {/*                Equity Crowdfunding & Lending Platform is a SPV project allowing LPs (and first Leading LP) to*/}
            {/*                generate potentially substantial and additional incomes with very high degree of flexibility.*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                The Beacon Foundation SPV provides to LPs’ (and first Leading LP) child(ren) a potential access to*/}
            {/*                top schools around the world while providing fame.*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                <b>The WISE Real Estate SPV: </b> real estate is regarded by Chinese people as a fundamental investment,*/}
            {/*                free of risk, especially in a long horizon. AUXILIUM has morphed the real estate business model and put it on steroid thanks to the injection of new technologies. Simply put, AUXILIUM has reached a higher return than the benchmark. This project exemplifies how AUXILIUM provides customization according to HNWIs’ risk profiles.*/}
            {/*            </li>*/}
            {/*            <li>*/}
            {/*                And so on.*/}
            {/*            </li>*/}
            {/*        </ul>*/}
            {/*    </li>*/}
            {/*</ul>*/}
            <CustomCollapse info={docText} color="white"></CustomCollapse>
          </div>
        </div>
      </CustomSection>
      <CustomSection
        backgroundColor="#8a8a8a"
        direction="column"
        className="Disclaimer"
      >
        <div
          className="content-container"
          style={{
            width: "100%",
            maxWidth: 960,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "16px",
          }}
        >
          <div
            className="text-container"
            style={{
              textAlign: "justify",
            }}
          >
            <h2 style={{ fontSize: 24, marginBottom: 8, marginTop: 15 }}>
              {" "}
              Disclaimer{" "}
            </h2>
            <p
              style={{ fontSize: 16, width: "768px", marginTop: 28 }}
              className="ok1_ball_box article"
            >
              The information herein has been obtained from, and any opinions
              herein are based upon, sources and analysis believed reliable. All
              opinions, forecasts and estimates herein reflect our judgment on
              the date of this website in its beta version and are therefore
              subject to be taken with the usual precautions. Therefore, while
              hypotheses used here, preliminary advice, or calculations are
              based on sound and established principles, they shall not be
              deemed as a formal proposal. Based on the preceding, we hereby
              decline any liability whatsoever for losses and/or damages of
              whatever kind (and sustained by whomever) that might result from
              the above. Names and denominations if any can change without
              notice. Part or totality of the document can be changed without
              notice.
            </p>
          </div>
        </div>
      </CustomSection>
      <BaseFooter />
    </Layout>
  );
};

export default HomePage;
