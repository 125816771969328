import React, { useState, useEffect } from 'react';
import { Card, Skeleton } from 'antd';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const StyledCard = styled(Card)`
  .ant-card-body {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: auto;
`;

const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled.h2`
  margin: 1rem 0;
`;

const PriceVolumeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Label = styled.p`
  color: grey;
  font-size: 0.8rem;
`;

const Value = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
`;

const ProductItem = ({ product }) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    return (
        <Link to={`/product/${product.id}`}>
            <StyledCard>
                { !imageLoaded && <Skeleton active /> }
                <ImageContainer>
                    <img
                        src={product.image}
                        alt={product.title}
                        style={{width: '100%', display: imageLoaded ? 'block' : 'none' }}
                        onLoad={() => setImageLoaded(true)}
                    />
                </ImageContainer>
                <ContentContainer>
                    <Title>{product.title}</Title>
                    <PriceVolumeContainer>
                        <div>
                            <Label>价格</Label>
                            <Value>{product.price}</Value>
                        </div>
                        <div>
                            <Label>销量</Label>
                            <Value>{product.volume}</Value>
                        </div>
                    </PriceVolumeContainer>
                </ContentContainer>
            </StyledCard>
        </Link>
    );
};
export default ProductItem;
