import React from 'react';
import { Form, Input, Button } from 'antd';
import './Auth.css'; // 引入新的CSS样式

const SignIn = () => {
    const onFinish = values => {
        console.log('Received values of form: ', values);
        // 你可以在这里处理登录逻辑
    };

    return (
        <Form
            name="normal_login"
            className="auth-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
        >
            <Form.Item
                name="email"
                rules={[
                    {
                        type: 'email',
                        message: 'The input is not valid E-mail!'
                    },
                    {
                        required: true,
                        message: 'Please input your E-mail!'
                    },
                ]}
            >
                <Input placeholder="Email" />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[{ required: true, message: 'Please input your Password!' }]}
            >
                <Input type="password" placeholder="Password" />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" className="auth-form-button">
                    Log in
                </Button>
            </Form.Item>
        </Form>
    );
};

export default SignIn;
