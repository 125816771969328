import HomePage from '../views/HomePage/HomePage';
import QuestionsPage from '../views/QuestionPage/QuestionPage';
import PDFRaeder from "../views/PDFReader/PDFRaeder";
import Auth from "../views/Auth/Auth";
import Mall from "../views/Mall/Mall";
import ProductDetail from "../views/ProductDetail/ProductDetail";
import PaymentPage from "../views/PaymentPage/PaymentPage";
import Receipt from "../views/Receipt/Receipt";

const routes = [
  {
    path: '/',
    component: HomePage,
    exact: true,
  },
  {
    path: '/pdfreader',
    component: PDFRaeder,
    exact: true,
  },
  {
    path: '/auth',
    component: Auth,
    exact: true,
  },{
    path: '/mall',
    component: Mall,
    exact: true,
  },{
    path: '/product/:id',
    component: ProductDetail,
    exact: true,
  },{
    path: '/payment/:id',
    component: PaymentPage,
    exact: true,
  },{
    path: '/receipt/:id',
    component: Receipt,
    exact: true,
  },
  // 以后可以在这里添加更多的路由配置
];

export default routes;