import React from 'react';
import ProductItem from '../ProductItem/ProductItem';
import styled from 'styled-components';

// 创建一个StyledProductList组件用于设置ProductList的样式
const StyledProductList = styled.div`
    display: flex;
    flex-wrap: wrap; // 当一行被填满时，项目将移到下一行
    justify-content: space-between; // 项目在行上均匀分布
`;

// 在ProductItem组件的文件中，创建一个StyledProductItem组件用于设置ProductItem的样式
const StyledProductItem = styled.div`
    flex-basis: calc((100% - 60px) / 4); // PC端每行4个，每个项目之间的空间为20px
    margin-bottom: 20px; // 设置底部间隔
    box-sizing: border-box;

    @media (max-width: 768px) { // 移动端每行2个
        flex-basis: calc((100% - 20px) / 2);
    }
`;

const ProductList = ({ products }) => {
    return (
        <StyledProductList>
            {products.map(product => (
                <StyledProductItem key={product.id}>
                    <ProductItem product={product} />
                </StyledProductItem>
            ))}
        </StyledProductList>
    );
};

export default ProductList;
