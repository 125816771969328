import React, { useState } from "react";
import { Layout, Menu, Button, Badge, Drawer } from "antd";
import {
  ShoppingCartOutlined,
  UserOutlined,
  MenuOutlined,
  AimOutlined,
} from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import UserStore from "../../stores/UserStore";
import useWindowWidth from "../../hooks/useWindowWidth";
import styled from "styled-components";
import "./TransparentHeader.css";

const { Header } = Layout;
const { SubMenu } = Menu;

const StyledMenu = styled(Menu)`
  float: right;

  @media (max-width: 768px) {
    .ant-drawer-title {
      font-size: 20px;
    }
  }
`;

const StyledSpan = styled.span`
  margin: 0 10px;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const StyledMenuSpan = styled.span`
  margin: 0rem 0.3rem 1rem 0.3rem;

  @media (max-width: 768px) {
    display: block;
    font-size: 1.2rem;
  }
`;

const TransparentHeader = observer(() => {
  const windowWidth = useWindowWidth();
  const [drawerVisible, setDrawerVisible] = useState(false);

  const handleOpenDrawer = () => {
    setDrawerVisible(true);
  };

  const handleCloseDrawer = () => {
    setDrawerVisible(false);
  };

  return (
    <Header
      style={{
        backgroundColor: "transparent",
        zIndex: 1,
        width: "100%",
        position: "absolute",
      }}
    >
      <div
        className="header-wrapper"
        style={{ maxWidth: 960, margin: "0 auto", textAlign: "center" }}
      >
        <h1
          className="logo-text"
          style={{
            fontWeight: "bolder",
            textAlign: "left",
            fontSize: "1.5rem",
            color: "orange",
          }}
        >
          AUXILIUM <span style={{ fontSize: 12 }}>Beta</span>
        </h1>
        {/*{windowWidth > 768 ? (*/}
        {/*    UserStore.isLoggedIn ? (*/}
        {/*        <StyledMenu mode="horizontal">*/}
        {/*            <SubMenu key="SubMenu" icon={<UserOutlined />} title={UserStore.userEmail}>*/}
        {/*                <Menu.Item key="logout">Logout</Menu.Item>*/}
        {/*            </SubMenu>*/}
        {/*            <Menu.Item key="cart" icon={<Badge count={5}><ShoppingCartOutlined /></Badge>} />*/}
        {/*        </StyledMenu>*/}
        {/*    ) : (*/}
        {/*        <div style={{ float: 'right' }}>*/}
        {/*            <StyledSpan>Login</StyledSpan>*/}
        {/*            <StyledSpan>Register</StyledSpan>*/}
        {/*        </div>*/}
        {/*    )*/}
        {/*) : (*/}
        {/*    <div style={{display: 'flex'}}>*/}
        {/*        <Button type="" onClick={handleOpenDrawer} style={{ float: 'right' }}>*/}
        {/*            <MenuOutlined />*/}
        {/*        </Button>*/}
        {/*        <Button type="">*/}
        {/*            <Badge count={5}><ShoppingCartOutlined /></Badge>*/}
        {/*        </Button>*/}
        {/*    </div>*/}
        {/*)}*/}
      </div>
      {/*<Drawer*/}
      {/*    title="Menu"*/}
      {/*    placement="right"*/}
      {/*    closable={false}*/}
      {/*    onClose={handleCloseDrawer}*/}
      {/*    visible={drawerVisible}*/}
      {/*    bodyStyle={{ padding: '1.1rem' }}*/}
      {/*>*/}
      {/*    {!UserStore.isLoggedIn ? (*/}
      {/*        <div>*/}
      {/*            <StyledMenuSpan>*/}
      {/*                <UserOutlined /> Logout*/}
      {/*            </StyledMenuSpan>*/}
      {/*            <StyledMenuSpan>*/}
      {/*                <UserOutlined /> Profile*/}
      {/*            </StyledMenuSpan>*/}
      {/*            <StyledMenuSpan>*/}
      {/*                <UserOutlined /> Order History*/}
      {/*            </StyledMenuSpan>*/}
      {/*            /!*<StyledMenuSpan >*!/*/}
      {/*            /!*    <Menu.Item key="cart" icon={<Badge count={5}><ShoppingCartOutlined /></Badge>} />*!/*/}
      {/*            /!*</StyledMenuSpan>*!/*/}
      {/*        </div>*/}
      {/*    ) : (*/}
      {/*        <div>*/}
      {/*            <StyledMenuSpan>*/}
      {/*                <UserOutlined /> Login*/}
      {/*            </StyledMenuSpan>*/}
      {/*            <StyledMenuSpan >*/}
      {/*                <AimOutlined style={{paddingRight: '0.5 rem'}}/> Register*/}
      {/*            </StyledMenuSpan>*/}
      {/*        </div>*/}
      {/*    )}*/}
      {/*</Drawer>*/}
    </Header>
  );
});

export default TransparentHeader;
