import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Row, Col } from 'antd';

const mockProduct = {
    name: "Example Product",
    image: "https://via.placeholder.com/150",
    price: 99.99,
    details: "This is a detailed description of the example product.",
    quantity: 1,
    color: "Blue",
};

const PaymentPage = () => {
    const navigator = useNavigate()
    const handlePayment = () => {
        // TODO: Add real payment processing here

        // Navigate to receipt page with product data
        navigator('/receipt/1', { state: { amount: mockProduct.price, productName: mockProduct.name, quantity: mockProduct.quantity, color: mockProduct.color } });
    };

    return (
        <Row align="middle" justify="center" style={{ minHeight: '100vh' }}>
            <Col span={18}>
                <Row >
                    <h1>{mockProduct.name}</h1>
                </Row>
                <Row >
                    <img src={mockProduct.image} alt={mockProduct.name} width="100%" />
                </Row>
                <Row >
                    <p style={{fontSize: '1rem'}}>{mockProduct.details}</p>
                </Row>
                <Row >
                    <p style={{fontSize: '1.25rem'}}>Quantity: {mockProduct.quantity}</p>
                </Row>
                <Row >
                    <p style={{fontSize: '1.25rem'}}>Color: {mockProduct.color}</p>
                </Row>
                <Row >
                    <p style={{fontSize: '1.25rem'}}>Price: ${mockProduct.price}</p>
                </Row>
                <Row >
                    <Button type="primary" size="large" onClick={handlePayment}>Pay</Button>
                </Row>
            </Col>
        </Row>
    );
};

export default PaymentPage;
