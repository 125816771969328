import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Tabs, Card, Typography, Button } from 'antd';
import './ProductDetail.css'; // 引入 CSS 文件

const { TabPane } = Tabs;
const { Title, Text } = Typography;

const ProductDetail = () => {
    const { productId } = useParams();
    const navigator = useNavigate()

    const [product, setProduct] = useState({
        id: '123',
        name: 'Product Name',
        image: 'https://i.seadn.io/gae/OLKVbj0-5hhF788Ea0dqj96WRuxcSqCxQ8_UD8T3DeVgWGcFOaQzLjYl2fRGjgBvZ7Gq9l9jDk0Ireyg_1bhpvBtphsed0kkA9N0WQ?auto=format&dpr=1&w=750',
        price: '99.99',
        details: 'This is a detailed description of the product.',
        reviews: [
            {id: 1, user: 'User 1', content: 'This product is great!'},
            {id: 2, user: 'User 2', content: 'This product is amazing!'}
        ]
    });

    // ...

    if (!product) {
        return <div>Loading...</div>;
    }
    const handlePayment = () => {
        // TODO: Add real payment processing here

        // Navigate to receipt page with product data
        navigator('/payment/1');

    };


    return (
        <div className="product-detail">
            <Card
                hoverable
                cover={<img alt={product.name} src={product.image} />}>
                <Title level={2}>{product.name}</Title>
                <Text type="secondary">${product.price}</Text>
                <Button type="primary" onClick={handlePayment}  size="large" style={{ margin: '20px 8px' }}>Buy Now</Button>
                <Tabs defaultActiveKey="1">
                    <TabPane tab="Details" key="1">
                        <Text>{product.details}</Text>
                    </TabPane>
                    <TabPane tab="Reviews" key="2">
                        {product.reviews.map(review => (
                            <div key={review.id}>
                                <Text strong>{review.user}</Text>
                                <p>{review.content}</p>
                            </div>
                        ))}
                    </TabPane>
                </Tabs>
            </Card>
        </div>
    );
};

export default ProductDetail;
