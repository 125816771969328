import React, {useState, useEffect} from "react";
import {Layout, Modal, Input, message, Alert, Pagination } from "antd";
import TransparentHeader from "../../components/TransparentHeader/TransparentHeader";
import { WarningOutlined } from '@ant-design/icons';
import { Document, Page, pdfjs } from 'react-pdf';
import './PDFReader.css'
import CustomSection from "../../components/CustomSection/CustomSection";
import PDF from '../../assets/axm.pdf'


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const PDFRaeder = () => {
debugger;
console.log('pdfRaeder');
    const [messageApi, contextHolder] = message.useMessage();
    const [email, setEmail] = useState('');
    const [inputToken, setInputToken] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [validationMethod, setValidationMethod] = useState('email');
    const [tokenWarning, setTokenWarning] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [token, setToken] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [isTokenVerified, setIsTokenVerified] = useState(false);


    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleOk = () => {
        const storedEmail = localStorage.getItem('email');
        const storedToken = localStorage.getItem('token');

        if (validationMethod === 'email') {
            if (!storedEmail) {
                message.error('Email has already been used to obtain a token');
                return;
            } else {
                const newToken = createToken(email);
                localStorage.setItem('email', email);
                localStorage.setItem('token', newToken);
                setToken(newToken);
                setTokenWarning(true);
            }
        } else {
            if (validateToken(inputToken, storedToken)) {
                setIsModalVisible(false);
                setIsTokenVerified(true);
                message.success('Verification is successful');
            } else {
                message.error('Token is invalid or expired');
            }
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const createToken = (email) => {
        const payload = {
            email,
            timestamp: new Date().getTime()
        };
        setToken(btoa(JSON.stringify(payload)))
        return btoa(JSON.stringify(payload));
    };

    const validateToken = (inputToken, storedToken) => {
        if (inputToken !== storedToken) {
            return false;
        }

        const payload = JSON.parse(atob(storedToken));
        const currentTime = new Date().getTime();
        const oneMinute = 60 * 1000 * 60 * 24 * 3;

        return currentTime - payload.timestamp < oneMinute;
    };
    let onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages)
    }
    const switchValidationMethod = () => {
        setValidationMethod(validationMethod === 'email' ? 'token' : 'email');
    };

    return (
        <Layout>
            <TransparentHeader />

            <Modal title={`Enter your ${validationMethod}`} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                <Input
                    type="text"
                    value={validationMethod === 'email' ? email : inputToken}
                    onChange={e => validationMethod === 'email' ? setEmail(e.target.value) : setInputToken(e.target.value)}
                    placeholder={`Enter your ${validationMethod}`}
                    style={{marginBottom: '16px', marginTop: '16px'}}
                />
                {tokenWarning &&
                    <>
                        <p>Your token is </p>
                        <Alert
                            message={`${token}`}
                            type="warning"
                            showIcon
                            icon={<WarningOutlined />}
                            style={{marginBottom: '16px'}}

                        />
                        <p> Please save it. It will be valid for 24 hours. </p>

                    </>

                }
                <div onClick={switchValidationMethod}
                     className="change-text-tab"
                     style={{marginBottom: '16px'}}
                >
                    Click here to validate by {validationMethod === 'email' ? 'token' : 'email'}
                </div>
            </Modal>
            <CustomSection>
                {isTokenVerified ?
                    <Document file={PDF} className="pdf-reader-wrapper" onLoadSuccess={onDocumentLoadSuccess}>
                        <Page pageNumber={pageNumber} />
                    </Document>
                    : null
                }
            </CustomSection>
        </Layout>
    )
}

export default PDFRaeder