import { makeAutoObservable } from "mobx";

class UserStore {
    isLoggedIn = false;
    userEmail = '';

    constructor() {
        makeAutoObservable(this)
    }

    logIn(email) {
        this.isLoggedIn = true;
        this.userEmail = email;
    }

    logOut() {
        this.isLoggedIn = false;
        this.userEmail = '';
    }
}

export default new UserStore();
