// Mall.jsx
import React, { useState, useEffect } from 'react';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel'
import ProductList from "../../components/ProductList/ProductList";
import TransparentHeader from "../../components/TransparentHeader/TransparentHeader";
import styled from 'styled-components';
import {Typography} from "antd";
const StyledMall = styled.div`
    max-width: 960px;
    margin: 130px auto 0 auto;  // 居中
    @media (max-width: 48rem) {
      padding: 0 20px;
      box-sizing: border-box;
      max-width: 100%;// Optional: You may also want to adjust padding on mobile
      margin: 10px auto;
    }
`;

// 创建一个StyledProductList组件用于设置ProductList的样式
const StyledProductList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)); // 移动端每行2个，PC端每行4个
    grid-gap: 20px; // 设置间隔
`;

// 在ProductItem组件的文件中，创建一个StyledProductItem组件用于设置ProductItem的样式
const StyledProductItem = styled.div`
    width: 100%;
    height: 0;
    padding-bottom: 100%;  // 使其成为一个正方形
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url(${props => props.image});  // 假设你将商品图片的url作为prop传入
    // 更多的样式...
`;


const Mall = () => {
    const [products, setProducts] = useState([]);
    const [images, setImages] = useState([]);

    // Assume that fetchProducts and fetchImages are functions that
    // fetch the list of products and carousel images respectively.
    useEffect(() => {
        const fetchProducts = async () => {
            // Fetch products from server
            // setProducts(fetchedProducts);
        };

        const fetchImages = async () => {
            // Fetch images from server
            // setImages(fetchedImages);
        };

        fetchProducts();
        fetchImages();
    }, []);

    useEffect(() => {
        const mockProducts = [
            { id: 1, name: 'Product 1', price: '$99.99', volume: 100, image: 'https://i.seadn.io/gae/LRmSxA2-ZgSRfWZ2MJo5a1VQ_gAZ675LSB0j8w--7lgkkD7h8-QN1-zeOLPzRWueBX9fcTPMssNgIrvqFLst0xjF1_SMs0V2WzRjPg?auto=format&dpr=1&w=750', details: 'Detail for product 1' },
            { id: 2, name: 'Product 2', price: '$89.99', volume: 200, image: 'https://i.seadn.io/gae/F1og3j1d9u3ykc2ouJ2xUShjuKv7vGHfXrfll6FeeC6L6U8MN2ZgNIgEzMIMrk_zIWMTB8ByWL2hk1NgsUBGnZxC_yDoXwfYt16Tnx4?auto=format&dpr=1&w=750', details: 'Detail for product 2' },
            { id: 3, name: 'Product 3', price: '$79.99', volume: 150, image: 'https://i.seadn.io/gae/F1og3j1d9u3ykc2ouJ2xUShjuKv7vGHfXrfll6FeeC6L6U8MN2ZgNIgEzMIMrk_zIWMTB8ByWL2hk1NgsUBGnZxC_yDoXwfYt16Tnx4?auto=format&dpr=1&w=750', details: 'Detail for product 3' },
            { id: 4, name: 'Product 1', price: '$99.99', volume: 100, image: 'https://i.seadn.io/gae/LRmSxA2-ZgSRfWZ2MJo5a1VQ_gAZ675LSB0j8w--7lgkkD7h8-QN1-zeOLPzRWueBX9fcTPMssNgIrvqFLst0xjF1_SMs0V2WzRjPg?auto=format&dpr=1&w=750', details: 'Detail for product 1' },
            { id: 5, name: 'Product 2', price: '$89.99', volume: 200, image: 'https://i.seadn.io/gae/F1og3j1d9u3ykc2ouJ2xUShjuKv7vGHfXrfll6FeeC6L6U8MN2ZgNIgEzMIMrk_zIWMTB8ByWL2hk1NgsUBGnZxC_yDoXwfYt16Tnx4?auto=format&dpr=1&w=750', details: 'Detail for product 2' },
            { id: 6, name: 'Product 3', price: '$79.99', volume: 150, image: 'https://i.seadn.io/gae/F1og3j1d9u3ykc2ouJ2xUShjuKv7vGHfXrfll6FeeC6L6U8MN2ZgNIgEzMIMrk_zIWMTB8ByWL2hk1NgsUBGnZxC_yDoXwfYt16Tnx4?auto=format&dpr=1&w=750', details: 'Detail for product 3' },
            { id: 7, name: 'Product 1', price: '$99.99', volume: 100, image: 'https://i.seadn.io/gae/LRmSxA2-ZgSRfWZ2MJo5a1VQ_gAZ675LSB0j8w--7lgkkD7h8-QN1-zeOLPzRWueBX9fcTPMssNgIrvqFLst0xjF1_SMs0V2WzRjPg?auto=format&dpr=1&w=750', details: 'Detail for product 1' },
            { id: 8, name: 'Product 2', price: '$89.99', volume: 200, image: 'https://i.seadn.io/gae/F1og3j1d9u3ykc2ouJ2xUShjuKv7vGHfXrfll6FeeC6L6U8MN2ZgNIgEzMIMrk_zIWMTB8ByWL2hk1NgsUBGnZxC_yDoXwfYt16Tnx4?auto=format&dpr=1&w=750', details: 'Detail for product 2' },
            { id: 9, name: 'Product 3', price: '$79.99', volume: 150, image: 'https://i.seadn.io/gae/F1og3j1d9u3ykc2ouJ2xUShjuKv7vGHfXrfll6FeeC6L6U8MN2ZgNIgEzMIMrk_zIWMTB8ByWL2hk1NgsUBGnZxC_yDoXwfYt16Tnx4?auto=format&dpr=1&w=750', details: 'Detail for product 3' },
            // Add more products as needed
        ];
        setProducts(mockProducts);

        const mockImages = [
            'https://i.seadn.io/gae/OLKVbj0-5hhF788Ea0dqj96WRuxcSqCxQ8_UD8T3DeVgWGcFOaQzLjYl2fRGjgBvZ7Gq9l9jDk0Ireyg_1bhpvBtphsed0kkA9N0WQ?auto=format&dpr=1&w=750',
            'https://i.seadn.io/gae/-UvZpYOd7A-1frIHPZKQfNfSa85owhvu-TpUhb67c34Vs9Hz6HYwvVyoNo8myUPwLCM2znNiOu3aYVYb8u3GNVnJVFDcwrGyli2twQ?auto=format&dpr=1&w=750',
            'https://i.seadn.io/gae/4u9zUHrrvDFiqrDrkO4kpnz0oW7veZRGwcNzzKReTHDqeXOJMHKZU-mYdl3FFXTOhmG_SeomHKAThMzs3OHiaRBCuIcj0OJLRcggIQ?auto=format&dpr=1&w=750',
            'https://i.seadn.io/gae/IuVsKqIlgaP37zNL6F8V8FWcwsqBX5iWwsE-xmawnMeBD6_-EifWbn7MnBH97qv7Y6AlcuKWohRPEQS37dfRLyW8O6P1NYX01K-Guu4?auto=format&dpr=1&w=750',
            'https://i.seadn.io/gae/OLKVbj0-5hhF788Ea0dqj96WRuxcSqCxQ8_UD8T3DeVgWGcFOaQzLjYl2fRGjgBvZ7Gq9l9jDk0Ireyg_1bhpvBtphsed0kkA9N0WQ?auto=format&dpr=1&w=750',
            'https://i.seadn.io/gae/-UvZpYOd7A-1frIHPZKQfNfSa85owhvu-TpUhb67c34Vs9Hz6HYwvVyoNo8myUPwLCM2znNiOu3aYVYb8u3GNVnJVFDcwrGyli2twQ?auto=format&dpr=1&w=750',
            'https://i.seadn.io/gae/4u9zUHrrvDFiqrDrkO4kpnz0oW7veZRGwcNzzKReTHDqeXOJMHKZU-mYdl3FFXTOhmG_SeomHKAThMzs3OHiaRBCuIcj0OJLRcggIQ?auto=format&dpr=1&w=750',
            'https://i.seadn.io/gae/IuVsKqIlgaP37zNL6F8V8FWcwsqBX5iWwsE-xmawnMeBD6_-EifWbn7MnBH97qv7Y6AlcuKWohRPEQS37dfRLyW8O6P1NYX01K-Guu4?auto=format&dpr=1&w=750',
        ];
        setImages(mockImages);

    }, []);

    return (
        <>
            <TransparentHeader />
            <StyledMall>
                <Typography className="mall-list-title">Hot</Typography>
                <ImageCarousel images={images} />
                <Typography className="mall-list-title">All Products</Typography>
                <ProductList products={products} />
            </StyledMall>


        </>

    );
};

export default Mall;
