import React from "react";
import { Anchor, Card } from "antd";
import "./CustomCard.css";

const { Meta } = Card;

const CustomCard = ({ title, description, anchor }) => {
//   console.log("anchor", anchor);
  return (
    <Card
      className="custom-card"
      style={{ width: 300, border: "none" }}
      bodyStyle={{ padding: 0 }}
    >
      <Meta
        title={<div style={{ fontWeight: "bold" }}>{title}</div>}
        description={description}
      />
      <a href={`#${anchor}`} style={{ marginLeft: 12, color: "#fff" }}>
        Find out more
      </a>
    </Card>
  );
};

export default CustomCard;
