// ReceiptPage.jsx
import React, { useEffect, useState } from 'react';
import { Card, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react'; // 导入二维码组件
import './ReceiptPage.css';

const { Title, Text } = Typography;

// 假设你有一个方法可以根据产品ID从模拟数据获取产品信息
const getProductById = (id) => {
    // 在这里实现你的逻辑...
    // 模拟数据
    const mockData = {
        "1": { name: 'Product 1', price: '100' },
        "2": { name: 'Product 2', price: '200' },
        "3": { name: 'Product 3', price: '300' },
    };
    return mockData[id];
};

const ReceiptPage = () => {
    const { id } = useParams(); // 获取URL中的产品ID
    const [product, setProduct] = useState(null);

    useEffect(() => {
        // 根据产品ID从模拟数据获取产品信息
        const fetchedProduct = getProductById(id);
        setProduct(fetchedProduct);
    }, [id]);

    // 生成收据信息
    const receipt = product && {
        orderId: '123456',
        productName: product.name,
        price: product.price,
        date: new Date().toLocaleString(),
    };

    return (
        <div className="receipt-page">
            <Card bordered={false} style={{ width: 300, margin: "auto", marginTop: "15%"}}>
                <Title level={3}>Receipt</Title>
                {receipt && (
                    <>
                        <Text style={{ display: "block", marginBottom: "1em" }}>
                            Order ID: {receipt.orderId}
                        </Text>
                        <Text style={{ display: "block", marginBottom: "1em", fontSize: "1.2em", fontWeight: "bold" }}>
                            {receipt.productName}
                        </Text>
                        <Text type="secondary" style={{ display: "block", marginBottom: "1em", fontSize: "1.2em" }}>
                            ${receipt.price}
                        </Text>
                        <Text style={{ display: "block", marginBottom: "1em" }}>
                            Date: {receipt.date}
                        </Text>
                        <QRCode value={receipt.orderId} />  {/* 在这里生成二维码 */}
                    </>
                )}
            </Card>
        </div>
    );
};

export default ReceiptPage;
