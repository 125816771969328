import React, { useState } from "react";
import { Row, Col, Popover } from "antd";
import {
  TwitterOutlined,
  InstagramOutlined,
  FacebookOutlined,
} from "@ant-design/icons";
import "./BaseFooter.css";

const CustomFooter = () => {
  const [visible, setVisible] = useState({ twitter: false, instagram: false });

  const handleClick = (platform) => {
    setVisible({ ...visible, [platform]: !visible[platform] });
  };

  return (
    <div className="footer-wrapper">
      <footer
        className="footer"
        style={{ width: "100%", marginBottom: 60, padding: "0 16px" }}
      >
        <Row gutter={[16, 16]} className="flexible-ct">
          <Col xs={24} sm={12} md={12} lg={13}>
            <h4>Contact Us</h4>
            <p>Email: info@axm.foundation</p>
            <p>
              Address: Unit 2A, 17/F, Glenealy Tower, 1 Glenealy, Central, Hong
              Kong
            </p>
          </Col>
          <Col xs={24} sm={12} md={12} lg={11} className="flexible right">
            <h4>Follow Us</h4>
            <p>Stay connected with us on social media.</p>
            <div className="social-icons">
              <Popover
                content="Twitter Coming Soon"
                trigger="click"
                visible={visible.twitter}
                placement={window.innerWidth < 768 ? "top" : "right"}
              >
                <a
                  href="#"
                  className="social-icon"
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick("twitter");
                  }}
                >
                  <TwitterOutlined />
                </a>
              </Popover>
              <a
                href="https://instagram.com/nain_neighbourhood?igshid=ZWIzMWE5ZmU3Zg=="
                target="_blank"
                className="social-icon"
              >
                <InstagramOutlined />
              </a>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: "30px", textAlign: "center" }}>
          <Col span={24}>
            <p>&copy; 2023 AUXILIUM, Inc. All Rights Reserved.</p>
          </Col>
        </Row>
      </footer>
    </div>
  );
};

export default CustomFooter;
