import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : '',
});

const api = {
  get: async (url, params = {}, config = {}) => {
    try {
      const response = await instance.get(url, { ...config, params });
      return response.data;
    } catch (error) {
      console.error(`[api.get] Error: ${error.message}`);
      throw error;
    }
  },

  post: async (url, data = {}, config = {}) => {
    try {
      const response = await instance.post(url, data, config);
      return response.data;
    } catch (error) {
      console.error(`[api.post] Error: ${error.message}`);
      throw error;
    }
  },
};

export default api;
