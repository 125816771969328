import React from 'react';
import { Collapse } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import './CustomCollapse.css'

const { Panel } = Collapse;

const customPanelStyle = {
  background: 'transparent',
  border: 0,
  marginBottom: 24,
  overflow: 'hidden',
  color: '#f1f1f1 !important'
};

const CustomCollapse = ({info, color = 'black'}) => {
  return (
      <Collapse
          className={`collapse-container s-${color}`}
          accordion
          colorText={color}
          expandIcon={({ isActive }) => (
              <RightOutlined rotate={isActive ? 90 : 0} />
          )}
          ghost
      >
        {info.map((item, index) => (
            <Panel header={item.title} key={index + 1} style={customPanelStyle}>
              {typeof item.content === 'string'
                  ? item.content.split('\n').map((line, lineIndex) => (
                      <React.Fragment key={`line-${lineIndex}`}>
                        {line}
                        {/* <br /> */}
                        {/* <br /> */}
                      </React.Fragment>
                  ))
                  : item.content
              }
            </Panel>
        ))}
      </Collapse>
  );
};

export default CustomCollapse;
