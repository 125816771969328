import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Carousel } from 'antd';
import styled from 'styled-components';
import './ImageCarousel.css'

const StyledCarouselContainer = styled.div`
  width: 100%;
`;

// CarouselItem will contain 4 images on desktop, and 1 image on mobile

const CarouselItem = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 48rem) {
    flex-direction: column;
  }
`;

// StyledImage will have 100% width on mobile, and 25% width on desktop
const StyledImage = styled.img`
  width: 100%;
  @media (min-width: 48rem) {
    width: calc(100% / 4 - 1rem); // subtract margin
    margin-right: 1rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;
const ImageCarousel = ({ images }) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 48rem)'
    })

    const chunks = [];

    // Chunk images into groups of 4 for desktop, or 1 for mobile
    const chunkSize = isDesktopOrLaptop ? 4 : 1;
    for (let i = 0; i < images.length; i += chunkSize) {
        chunks.push(images.slice(i, i + chunkSize));
    }

    return (
        <StyledCarouselContainer>
            <Carousel>
                {chunks.map((chunk, index) => (
                    <CarouselItem key={index}>
                        {chunk.map((image, index) => (
                            <StyledImage key={index} src={image} alt={`slide-${index}`} />
                        ))}
                    </CarouselItem>
                ))}
            </Carousel>
        </StyledCarouselContainer>
    );
};

export default ImageCarousel;
