import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const CustomSection = ({ backgroundColor, direction='row', className, flex = 'flex', id, color = '#000', children }) => {
  return (
    <Content id={id} className={className} style={{ backgroundColor: backgroundColor, color: color, minHeight: '100vh', display: flex, flexDirection: direction, justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}>
      {children}
    </Content>
  );
};

export default CustomSection;
