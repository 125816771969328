import React from "react";
import { Carousel, Avatar, Typography } from "antd";
import { useMediaQuery } from "react-responsive";
import "./MemberCarousel.css";

const { Text } = Typography;

const MemberCarousel = () => {
  const isMobileDevice = useMediaQuery({ maxWidth: 768 });

  // 根据设备屏幕宽度设置轮播属性
  const slidesToShow = isMobileDevice ? 1 : 3;
  const slidesToScroll = isMobileDevice ? 1 : 3;

  const members = [
    {
      name: "Ms. Selfie",
      avatar: require("../../assets/img/avatar/selfie.png"),
      title: "Community",
      bio: "Business director at global leading tech co., ambassador and evangelist for AR, VR, and metaverse. Resigned experience officer and generous shopper at various fashion platforms online and offline. Avalon agitator.",
    },
    {
      name: "Matrix",
      avatar: require("../../assets/img/avatar/Matrix.png"),
      title: "Economics / Modeling",
      bio: "Ph.D. alumni from University of Bristol specialised in Economics and Marketing. Experienced blockchain industry researcher and passionate gamer who is enthusiastic about contributing to the next generation of gaming economics system and business models.",
    },
    {
      name: "Mr. Swot",
      avatar: require("../../assets/img/avatar/swot.png"),
      title: "Concept Design / Real Estate",
      bio: "University of Cambridge alumni. VP, managing a multi-billion sized real estate fund. 10 years of work experience in international real estate and a China and UK chartered property valuer. 8-year Argentine tango dancer and teacher who speaks, on top of Chinese and English, Spanish. ",
    },
    {
      name: "Money Bag",
      avatar: require("../../assets/img/avatar/moneybag.png"),
      title: "Retail & e-Commerce",
      bio: "Executive at one of the world’s largest online marketplaces. E-commerce expert with extensive experience in cross-border operations. Trekking is her way to feel the world and challenge own limit.",
    },
    {
      name: "Royal Flush",
      avatar: require("../../assets/img/avatar/RoyalFlush.png"),
      title: "Database & Gamification",
      bio: "CUHK alumni. Web3 data scientist proficient at analysing and extracting insights from decentralized digital systems. Outside of work, you will find him exploring his passions for sports, poker, and electro  music.",
    },
    {
      name: "Habeas Corpus",
      avatar: require("../../assets/img/avatar/HabeasCorpus .png"),
      title: "Agency & Legal",
      bio: "Tsinghua University alumni. Highly professional corporate lawyer admitted to practice in New York. She is pursuing her new love for golf.",
    },
    {
      name: "Smarty",
      avatar: require("../../assets/img/avatar/Smarty.png"),
      title: "Smart Contract",
      bio: "Johns Hopkins University alumni. Senior full stack engineer at the world’s top fintech company. Smart contract developer who is obsessed with solving problems and exploring the fusion of Web3 and everyday life. Keen learner and dedicated new parent.",
    },
    {
      name: "Cody",
      avatar: require("../../assets/img/avatar/Cody.png"),
      title: "Smart Contract",
      bio: "Purdue University alumni. Software engineer experienced in backend development and infrastructure. Coder who enjoys the thrill of online coding competitions.",
    },
    {
      name: "Buzzin’",
      avatar: require("../../assets/img/avatar/Buzzin’.png"),
      title: "KOL/KOC Marcom",
      bio: "Seasoned digital marketer and frontliner of influencer marketing and social commerce. Rich work experience from the world’s largest cosmetic company and global social media giant. Sports enthusiast and relocated to Japan to indulge own passion for snowboarding.",
    },
    {
      name: "Wiki",
      avatar: require("../../assets/img/avatar/Wiki.png"),
      title: "Interface",
      bio: "Senior front-end developer at one of the multinational tech giants. Devoted experiencer of Web3. Working mom of a lovely baby girl.",
    },
    {
      name: "Clicky",
      avatar: require("../../assets/img/avatar/Clicky.png"),
      title: "Design Lead",
      bio: "Award-winning photographer recognized by the Wedding and Portrait Photography International (WPPI). With over 17 years of experience, Clicky shot documentaries and worked with renowned agencies and magazines worldwide. He is developing his new hobby of creating digital art through AI and exhibiting in the metaverse.",
    },
    {
      name: "Elmes",
      avatar: require("../../assets/img/avatar/Elmes.png"),
      title: "3D Design",
      bio: "President of Digital Entertainment Branch of China Entertainment Association: GDC (Global Digital Creations). Experienced 3D designer. Chase freedom and truth.",
    },
    {
      name: "Augenstern",
      avatar: require("../../assets/img/avatar/Augenstern.png"),
      title: "VI Design",
      bio: "Chief designer and project manager. Once Best Creative Award winner in VI design. I pay attention to the communication between people and design. I value the most user experience in a design and am devoted to bringing humanity to my design.",
    },
    // ...
  ];

  return (
    <div className="carousel-container">
      <Carousel
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToScroll}
        className="member-carousel"
        dots={true}
        style={{ width: "100%" }}
      >
        {members.map((member, index) => (
          <div key={index} className="member-container">
            <Avatar src={member.avatar} size={64} />
            <Text className="member-name" strong style={{ color: "#f2f2f2" }}>
              {member.name}
            </Text>
            <Text
              className="member-name"
              strong
              strong
              style={{ color: "#f2f2f2" }}
            >
              {member.title}
            </Text>
            <Text
              className="member-bio"
              italic
              strong
              style={{ color: "#f2f2f2" }}
            >
              {member.bio}
            </Text>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default MemberCarousel;
